



























































import Confirmacao from '@/components/ui/Confirmacao.vue'
import AlertModule from '@/store/vuex/aplicacao/AlertModule'
import { VendaModule } from '@/store/vuex/venda/VendaStore'
import { ReabrirVendaUseCase } from '@/usecases'
import { Vue, Component, Watch, Ref } from 'vue-property-decorator'

@Component({
	components: {
		Confirmacao,
	},
})
export default class DialogoDeReabrirVenda extends Vue {
	@Ref() form!: HTMLFormElement
	@Ref('confirmacao') confirmacao!: Confirmacao
	mostra = false
	identificador = ''
	reabrirVendaUseCase = new ReabrirVendaUseCase()
	reabrindo = false
	devolver = false

	mostrar() {
		this.mostra = true
	}

	fechar() {
		this.mostra = false
	}

	async enviaIdentificador() {
		try {
			if (!this.form.validate()) return
			this.reabrindo = true
			await this.reabrirVendaUseCase.reabrir(this.identificador)
			if (
				VendaModule.vendaAtual?.isConsignado ||
				VendaModule.vendaAtual?.isDemonstracao
			) {
				await this.confirmacao.mostrar()
			}

			if(this.devolver) {
				this.$emit('devolver', true)
				this.fechar()
			} else {
				this.$emit('devolver', false)
				this.fechar()
			}

		} catch (e) {
			AlertModule.setError(e)
		} finally {
			this.reabrindo = false
			this.devolver = false
		}
	}

	devolverTudo() {
		VendaModule.vendaAtual?.itens.forEach(item => (item.quantidade = 0))
		this.devolver = true
	}

	@Watch('mostra')
	onChangeMostra(mostra: boolean, mostraAnterior: boolean) {
		if (mostra && !mostraAnterior) {
			this.identificador = ''
			window.requestAnimationFrame(() => {
				this.form.resetValidation()
			})
		}
	}
}
