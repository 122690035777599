







































































import {
	Vue,
	Component,
	PropSync,
	Watch,
	Ref,
} from 'vue-property-decorator'
import { Pagamento, Venda } from '@/models'
import { formatarMoeda } from '@/shareds/formatadores'
import Confirmacao from '@/components/ui/Confirmacao.vue'
import { VendaModule } from '@/store/vuex/venda/VendaStore'
import { obterItensComTaxaAplicada } from '@/shareds/venda-shareds'



@Component({
	components: {
		Confirmacao,
	},
})
export default class DialogoDeEdicaoDePagamento extends Vue {
	@PropSync('value', { type: Array, default: () => [] }) pagamentos!: Omit<
		Pagamento,
		'id'
	>[]
	@Ref() confirmacaoDeExcluirPagamento!: Confirmacao
	@Ref() botaoDeFechar?: { $el: HTMLButtonElement }

	formatarMoeda = formatarMoeda
	obterItensComTaxaAplicada = obterItensComTaxaAplicada

	mostra: boolean | null = null
	contadorTef = 0
	vendasss: Venda | null = null

	get turnoDeVenda() {
		return VendaModule.turnoDeVenda
	}

	@Watch('mostra')
	onChangeMostra() {
		setTimeout(() => {
			if (!this.botaoDeFechar) return
			this.botaoDeFechar.$el.focus()
		})
	}

	@Watch('pagamentos')
	onChangePagamentos(pagamentos: DialogoDeEdicaoDePagamento['pagamentos']) {
		if (!pagamentos.length) this.mostra = false
	}

	obterSubtituloDeExclusao(pagamento: Omit<Pagamento, 'id'>) {
		const textoDeParcelas = pagamento.qtdeDeParcelas
			? ` em ${pagamento.qtdeDeParcelas}x`
			: ''
		return `R$${formatarMoeda(pagamento.valor)} ${
			pagamento.tipoDePagamento.nome
		}${textoDeParcelas}`
	}

	async excluirPagamento(indice) {
		const pagamento = this.pagamentos[indice]
	
		if (pagamento.detalhesDoPagamentoId !== null 
			&& (pagamento.tipoDePagamento.formaDePagamento === 'TEF Crédito' 
			|| pagamento.tipoDePagamento.formaDePagamento === 'TEF Débito')) {
			this.$emit('cancelarPagamento', indice)
		} else {
			this.$emit('cancelar', indice)
		}
	}
}
