





















































import SeletorDeUsuario from '@/components/usuario/SeletorDeUsuario.vue'
import { Loja, ModalidadeDeVenda, TurnoDeVenda, Venda } from '@/models'
import { VendaModule } from '@/store/vuex/venda/VendaStore'
import { FindModalidadeDeVendaUseCase } from '@/usecases'
import { Vue, Component, Ref, Watch, Prop } from 'vue-property-decorator'

@Component({
	components: {
		SeletorDeUsuario,	
	},
})
export default class DialogoDeInformarModalidade extends Vue {
	@Ref() form!: HTMLFormElement
	@Prop() loja!: Loja

	carregandoModalidade: boolean | false = false
	mostra: boolean | null = false
	modalidades: ModalidadeDeVenda[] | [] = []
	modalidade: ModalidadeDeVenda | null = null
	findModalidadeDeVendaUseCase = new FindModalidadeDeVendaUseCase()

	get venda() {
		return VendaModule.vendaAtual as Venda
	}
	
	set venda(venda: Venda) {
		VendaModule.setVendaAtual(venda)
	}

	get turno() {
		return VendaModule.turnoDeVenda as TurnoDeVenda
	}

	mostrar() {
		this.mostra = true
		this.modalidade = VendaModule.vendaAtual?.modalidadeDeVenda || null
	}

	fechar(){
		this.mostra = false
	}

	informarModalidade() {
		this.venda.modalidadeDeVenda = this.modalidade

		this.mostra = false
	}

	@Watch('mostra')
	onChangeMostra(mostra: boolean, mostraAnterior: boolean) {
		if (mostra && !mostraAnterior) {
			this.carregar()
			this.modalidade = this.venda.modalidadeDeVenda
			window.requestAnimationFrame(() => {
				this.form.resetValidation()
			})
		}
	}

	async carregar(): Promise<Array<ModalidadeDeVenda>> {
		this.carregandoModalidade = true
		const listaDeModalidades = await this.findModalidadeDeVendaUseCase.find(this.loja.id)
		this.modalidades = listaDeModalidades.map(modalidadeMap => {
			return {
				id: modalidadeMap.id,
				modalidade: modalidadeMap.modalidade,
				origem: modalidadeMap.origem,
				lojaId: (modalidadeMap as any).loja.id,
				sequencial: modalidadeMap.sequencial,
			}
		})
		this.carregandoModalidade = false
		
		return listaDeModalidades
	}

}
