
























































import { MovimentacaoDeCaixa } from '@/models'
import { downloadFilesFromS3 } from '@/shareds/s3/files'
import AlertModule from '@/store/vuex/aplicacao/AlertModule'
import { Vue, Component, Prop } from 'vue-property-decorator'

const PASTA_DA_MOVIMENTACAO = 'movimentacoes-de-caixa'

@Component
export default class BotaoDeDownloadDeAnexoDeFechamento extends Vue {
	@Prop() movimentacao!: MovimentacaoDeCaixa
	@Prop() turnoDeVendaId!: string

	baixandoAnexos = false

	baixarArquivo(caminhoDaPasta: string, nomeDoArquivo: string) {
		try {
			this.baixandoAnexos = true
			downloadFilesFromS3(caminhoDaPasta, nomeDoArquivo)
		} catch (error) {
			AlertModule.setError('Houve um problema ao baixar o arquivo')
		} finally {
			this.baixandoAnexos = false
		}
	}

	extrairCaminho(url: string) {
		const filePath = url.replace(/.*\/([^/]+\/[^/]+\/[^/]+)\/[^/]+$/, "$1");

		return filePath
	}

	extrairNomeDosDocumentos(url: string) {
		const lastSlashIndex = url.lastIndexOf("/")
		const fileName = url.substring(lastSlashIndex + 1)
		return fileName
	}

	get caminhoDaPastaDeMovimentacoes() {
		return `${PASTA_DA_MOVIMENTACAO}/${this.turnoDeVendaId}/${this.movimentacao.tipoMovimentacao}`
	}
}

