var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-bottom-sheet',{attrs:{"persistent":"","fullscreen":"","scrollable":"","no-click-animation":""},on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"esc",27,$event.key,["Esc","Escape"])){ return null; }$event.stopPropagation();return _vm.voltar.apply(null, arguments)}},model:{value:(_vm.syncMostra),callback:function ($$v) {_vm.syncMostra=$$v},expression:"syncMostra"}},[_c('v-card',{staticClass:"d-flex flex-column",attrs:{"tile":"","dark":""}},[_c('v-card-title',[_c('h3',[_c('v-icon',{staticClass:"mr-2",on:{"click":_vm.voltar}},[_vm._v("mdi-arrow-left")])],1),_vm._v(" Retorno de Demonstração/Consignado "),_c('v-btn',{staticClass:"ml-auto",attrs:{"dark":"","icon":""},on:{"click":_vm.fechar}},[_c('v-icon',[_vm._v("mdi-close")])],1)],1),_c('v-card-text',[_c('v-form',{ref:"form",staticClass:"mx-auto",staticStyle:{"width":"auto"},attrs:{"disabled":_vm.reabrindo},on:{"submit":function($event){$event.preventDefault();return (function () { return (_vm.etapaDoRetorno === 'escolhendo-itens' ? _vm.enviaIdentificador() : _vm.buscarVenda()); }).apply(null, arguments)}}},[(_vm.etapaDoRetorno === 'filtros')?_c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('CampoDeCodigoDaVenda',{ref:"campoDeNumeroDaVenda",attrs:{"filled":"","color":"success","label":"Código da Venda","type":"text","hide-details":"","disabled":!!_vm.parametros.numeroDaNota ||
									!!_vm.parametros.serieFiscal ||
									_vm.isClienteInformado ||
									_vm.isProdutoInformado ||
									_vm.reabrindo},on:{"click:clear":function($event){_vm.parametros.identificador = ''}},model:{value:(_vm.parametros.identificador),callback:function ($$v) {_vm.$set(_vm.parametros, "identificador", $$v)},expression:"parametros.identificador"}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('div',{staticClass:"divider"},[_c('strong',{staticClass:"px-3",staticStyle:{"background-color":"#1E1E1E"}},[_vm._v("OU")])])]),_c('v-col',{attrs:{"cols":"6"}},[_c('v-text-field',{ref:"campoDeNumeroDaNota",attrs:{"color":"success","filled":"","autocomplete":"off","label":"Nº Nota Fiscal","min":"0","type":"number","inputmode":"numeric","disabled":!!_vm.parametros.identificador ||
									_vm.isClienteInformado ||
									_vm.isProdutoInformado ||
									_vm.reabrindo},on:{"click:clear":function($event){_vm.parametros.numeroDaNota = ''}},model:{value:(_vm.parametros.numeroDaNota),callback:function ($$v) {_vm.$set(_vm.parametros, "numeroDaNota", $$v)},expression:"parametros.numeroDaNota"}})],1),_c('v-col',{attrs:{"cols":"6"}},[_c('v-text-field',{attrs:{"color":"success","filled":"","autocomplete":"off","label":"Série","type":"text","disabled":!!_vm.parametros.identificador ||
									_vm.isClienteInformado ||
									_vm.isProdutoInformado ||
									_vm.reabrindo},on:{"click:clear":function($event){_vm.parametros.serieFiscal = ''}},model:{value:(_vm.parametros.serieFiscal),callback:function ($$v) {_vm.$set(_vm.parametros, "serieFiscal", $$v)},expression:"parametros.serieFiscal"}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('div',{staticClass:"divider"},[_c('strong',{staticClass:"px-2",staticStyle:{"background-color":"#1E1E1E"}},[_vm._v("OU")])])]),_c('v-col',{attrs:{"cols":"12"}},[_c('SeletorDeCliente',{attrs:{"label":"Clientes","return-object":"","disabled":!!_vm.parametros.numeroDaNota ||
								!!_vm.parametros.serieFiscal ||
								!!_vm.parametros.identificador ||
								_vm.reabrindo,"filled":""},on:{"clienteNaoExiste":_vm.clienteNaoExiste},model:{value:(_vm.parametros.cliente),callback:function ($$v) {_vm.$set(_vm.parametros, "cliente", $$v)},expression:"parametros.cliente"}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('BuscaDeProdutoDropdown',{ref:"campoDeProduto",attrs:{"label":"Produto","filtros":_vm.tiposDeProdutos,"return-object":"","requerFilho":"","filled":"","disabled":!!_vm.parametros.numeroDaNota ||
									!!_vm.parametros.serieFiscal ||
									!!_vm.parametros.identificador ||
									_vm.reabrindo,"clearable":""},model:{value:(_vm.parametros.produto),callback:function ($$v) {_vm.$set(_vm.parametros, "produto", $$v)},expression:"parametros.produto"}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-btn',{attrs:{"data-cy":"buscar","color":"graygrey darken-2","block":""},on:{"click":_vm.limparBusca}},[_c('v-icon',{attrs:{"left":""}},[_vm._v(" mdi-broom ")]),_vm._v(" Limpar Filtros ")],1)],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-btn',{attrs:{"data-cy":"buscar","color":"success","block":"","disabled":!_vm.parametros.identificador &&
								!(_vm.parametros.numeroDaNota && _vm.parametros.serieFiscal) &&
								!_vm.isClienteInformado &&
								!_vm.isProdutoInformado,"loading":_vm.reabrindo},on:{"click":function () { return (_vm.etapaDoRetorno === 'escolhendo-itens' ? _vm.enviaIdentificador() : _vm.buscarVenda()); }}},[_vm._v(" Buscar ")])],1)],1)],1):_vm._e(),(_vm.etapaDoRetorno === 'pesquisas')?_c('v-container',{attrs:{"fluid":""}},[(!_vm.buscando)?_c('v-card',{staticClass:"mt-2"},[_c('v-data-table',{attrs:{"headers":_vm.headersVendas,"items":_vm.vendasFormatadas,"hideCreate":"","loading":_vm.buscando,"sort-by":"dataHora","sort-desc":[true],"hide-default-footer":""},scopedSlots:_vm._u([{key:"item.actions",fn:function(ref){
								var item = ref.item;
return [_c('div',{staticClass:"d-flex flex-nowrap justify-center"},[_c('DialogoDeDetalhesDaVenda',{attrs:{"venda":item,"exibirDetalheCompleto":false},on:{"devolverVenda":_vm.selecionarVendaParaRetorno},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
								var dialog = ref.on;
return [_c('v-tooltip',{attrs:{"bottom":"","max-width":"300"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
								var tooltip = ref.on;
return [_c('v-icon',_vm._g({staticClass:"mr-2",attrs:{"small":""}},Object.assign({}, tooltip, dialog)),[_vm._v(" mdi-eye ")])]}}],null,true)},[_vm._v(" Detalhes da venda ")])]}}],null,true)}),_c('Confirmacao',{attrs:{"width":"300","titulo":"Reabrir esta venda?","subtitulo":("<div>" + (item.identificador) + "</div>")},on:{"confirmar":function () { return _vm.selecionarVendaParaRetorno(item); }},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
								var dialog = ref.on;
return [_c('v-tooltip',{attrs:{"bottom":"","max-width":"300"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
								var tooltip = ref.on;
return [_c('v-icon',_vm._g({staticClass:"ml-2",attrs:{"small":""}},Object.assign({}, dialog, tooltip)),[_vm._v(" "+_vm._s('mdi-keyboard-return')+" ")])]}}],null,true)},[_vm._v(" Retornar ")])]}}],null,true)})],1)]}},{key:"footer",fn:function(){return [_c('RodapePersonalizado',{attrs:{"paginacao":_vm.paginacao,"total":_vm.vendasFormatadas.length,"loading":_vm.buscando},on:{"navegar":_vm.atualizarPagina}})]},proxy:true}],null,true)})],1):_c('v-card',[_c('AppLoading')],1)],1):_vm._e()],1)],1),_c('Confirmacao',{ref:"confirmacao",attrs:{"width":"300","opcaoPadrao":"Sim","titulo":"Deseja devolver tudo?","subtitulo":"Todos os itens serão zerados","persistent":""},on:{"confirmar":_vm.devolverTudo,"negar":_vm.fechar}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }