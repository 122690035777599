

























































































































import {
	Vue,
	Component,
	PropSync,
	Prop,
	Ref,
	Watch,
} from 'vue-property-decorator'
import { Desconto, Venda } from '@/models'
import { formatarMoeda, formatarDecimal } from '@/shareds/formatadores'
import CampoDecimal from '@/components/ui/CampoDecimal.vue'
import CampoDePercentual from '@/components/ui/CampoDePercentual.vue'
import {
	obterDescontoDaVenda,
	obterTotalDaVenda,
} from '@/shareds/venda-shareds'
import { FindPerfilUseCase, FindUsuarioUseCase } from '@/usecases'
import { VendaModule } from '@/store/vuex/venda/VendaStore'
import UserLoginStore from '@/store/vuex/authentication/UserLoginStore'
import { ParametrosParaAutenticacao } from '@/models/RegraDeNegocio'

@Component({
	components: {
		CampoDecimal,
		CampoDePercentual,
	},
})
export default class BotaoDeDesconto extends Vue {
	@PropSync('value') desconto!: Desconto | null
	@Prop() venda!: Venda
	@Ref() botaoDeAcaoDeDesconto!: { $el: HTMLButtonElement }
	@Ref() campoDeValor!: CampoDecimal | CampoDePercentual
	@Ref() form!: HTMLFormElement
	@Prop({ type: Boolean }) disabled!: boolean
	@Ref() menuDeDesconto!: { $el: HTMLMenuElement }

	findUsuarioUseCase = new FindUsuarioUseCase()
	findPerfilUseCase = new FindPerfilUseCase()

	formValid = false
	CampoDePercentual = CampoDePercentual
	CampoDecimal = CampoDecimal

	formatarDecimal = formatarDecimal
	mostraMenuDeDesconto = false

	descontoEmEdicao: Desconto | null = null

	get descontoEValido() {
		return () => {
			if (!this.descontoEmEdicao) return true
			if (
				!this.descontoEmEdicao.isPercentual &&
				this.descontoEmEdicao.valor > this.total - 0.01
			)
				return 'Valor maior que o total da venda'
			if (
				this.descontoEmEdicao.isPercentual &&
				this.descontoEmEdicao.valor > 99.99
			)
				return 'Valor não pode ser maior que 99.99%'
			return true
		}
	}

	get lojaDaVendaAtual(): string | undefined {
		return VendaModule.turnoDeVenda
			? VendaModule.vendaAtual?.pontoDeVenda?.loja.id || undefined
			: undefined
	}

	get total() {
		return obterTotalDaVenda(this.venda)
	}

	get componenteDeCampoDeValor() {
		return this.descontoEmEdicao && this.descontoEmEdicao.isPercentual
			? 'CampoDePercentual'
			: 'CampoDecimal'
	}

	setPercentual(valor: boolean) {
		if (!this.descontoEmEdicao) return
		this.descontoEmEdicao.isPercentual = valor
	}

	get valorDoDesconto() {
		if (!this.desconto) return 0
		return obterDescontoDaVenda({
			...this.venda,
			desconto: this.desconto,
		})
	}

	get valorDoDescontoFormatado() {
		return formatarMoeda(this.valorDoDesconto)
	}

	get podeConcederDesconto() {
		return UserLoginStore.permiteRegraDeNegocio('pode-conceder-desconto')
	}

	created() {
		document.addEventListener('keydown', this.atalhoDeDesconto)
	}

	destroyed() {
		document.removeEventListener('keydown', this.atalhoDeDesconto)
	}

	atalhoDeDesconto(event: KeyboardEvent) {
		if (event.altKey && event.key.toLowerCase() === 'd') {
			event.preventDefault()
			this.botaoDeAcaoDeDesconto.$el.click()
		}
	}

	removerDesconto() {
		this.venda.desconto = null
		if(this.venda.cashbackAplicado) this.venda.cashbackAplicado = false
	}

	@Watch('descontoEmEdicao.isPercentual')
	onChangeIsPercentual(isPercentual: boolean) {
		if (!this.desconto) return
		this.desconto.valor = isPercentual
			? this.desconto.valor / 100
			: this.desconto.valor
		setTimeout(() => {
			this.campoDeValor.focus()
		})
	}

	async concederDesconto() {
		const params: ParametrosParaAutenticacao = {
			lojaId: this.lojaDaVendaAtual,
			regra: 'pode-conceder-desconto',
		}
		if (
			!this.podeConcederDesconto &&
			!(await VendaModule.autenticarAcao(params))
		)
			return
		this.confirmarDesconto()
	}

	async acaoDeDesconto() {
		this.mostraMenuDeDesconto = !this.mostraMenuDeDesconto
		this.descontoEmEdicao = this.desconto
			? { ...this.desconto }
			: {
				isPercentual: true,
				valor: 0,
			}
		if (!this.mostraMenuDeDesconto) return
		setTimeout(() => {
			this.campoDeValor.focus()
		}, 100)
	}

	async confirmarDesconto() {
		if (!this.form.validate()) return
		this.$emit('input', this.descontoEmEdicao)
		this.mostraMenuDeDesconto = false
		this.botaoDeAcaoDeDesconto.$el.focus()
	}
}
