















































































































































































import { ItemDaVenda, Cliente, Venda } from '@/models'
import { Vue, Component } from 'vue-property-decorator'
import { formatarCnpjOuCpf, formatarMoeda } from '@/shareds/formatadores'
import AlertModule from '@/store/vuex/aplicacao/AlertModule'
import CampoDeItemDeVenda from '@/components/venda/CampoDeItemDeVenda.vue'
import { VendaModule } from '@/store/vuex/venda/VendaStore'
import { converterProdutoParaProdutoSimples } from '@/shareds/produto-shareds'
import {  FindProdutoUseCase  } from '@/usecases'
import VueQuagga from "vue-quaggajs"
import { obrigatorio } from '@/shareds/regras-de-form'
import { ItemDeRomaneio } from '@/models/Romaneio'
import CampoDeClienteNoCaixa from './CampoDeClienteNoCaixa.vue'
import AplicarTabelaDePrecoUseCase from '@/usecases/tabela-de-precos/AplicarTabelaDePrecoUseCase'
import { obterTotalDoItem, obterTotalDoItemSemDesconto } from '@/shareds/venda-shareds'

Vue.use(VueQuagga);


@Component({
	components:{
		CampoDeItemDeVenda,
		CampoDeClienteNoCaixa,
	},
})
export default class DialogoDeIdentificacaoDaTroca extends Vue {
	obrigatorio = obrigatorio
	formatarMoeda = formatarMoeda
	obterTotalDoItem = obterTotalDoItem
	obterTotalDoItemSemDesconto = obterTotalDoItemSemDesconto

	itensBipados: ItemDeRomaneio[] = []
	findProdutoUseCase = new FindProdutoUseCase()
	buscandoCliente = false

	valorLido: string | null = null
	loading = false
	mostrarDialogoLeituraDeCodigoBarras = false
	showCamera = false
	buscandoProduto = false
	mostraConsultaDeProduto = false
	carregandoCardDeVerificacao = false
	mostra = false

	cpfOuCnpjDoClienteDoDialogo = this.cpfOuCnpjDoCliente

	set venda(venda: Venda) {
		VendaModule.setVendaAtual(venda)
	}

	get venda() {
		return VendaModule.vendaAtual as Venda
	}

	get lojaDaVenda() {
		return VendaModule.lojaDaVenda
	}

	get cpfOuCnpjDoCliente(): string {
		return VendaModule.vendaAtual && (VendaModule.vendaAtual.cpfDoCliente)
			? formatarCnpjOuCpf(VendaModule.vendaAtual.cpfDoCliente)
			: 
			VendaModule.vendaAtual && VendaModule.vendaAtual.cliente
				? formatarCnpjOuCpf(VendaModule.vendaAtual.cliente.cnpjOuCpf)
				:
				''
	}
	
	set cpfOuCnpjDoCliente(cpf: string) {
		if (!VendaModule.vendaAtual) return

		VendaModule.vendaAtual.cpfDoCliente = cpf
	}

	mostrar(){
		this.mostra = true
	}

	readerSize = {
		width: 860,
		height: 480,
	}

	async logIt(data: any) {
		this.showCamera = false
		this.buscandoProduto = true
		this.beep()
		this.valorLido = data.codeResult.code

		if (this.valorLido && this.lojaDaVenda) {
			try {
				const produto = await this.findProdutoUseCase.findProdutoComEstoque(
					this.valorLido,
					this.lojaDaVenda.id,
				)

				const item: ItemDaVenda = {
					id: '',
					produto: converterProdutoParaProdutoSimples(produto),
					quantidade: 0,
					desconto: { isPercentual: true, valor: 0.0 },
					preco: produto.preco || 0,
					precoDeCusto: 0,
					idConjunto: null,
					descontoVarejo: 0,
					percentualCashback: 0,
					diasParaEfetivarCashback: 0,
					isBrinde: false,
					idItemNotaOrigem: null,
					chaveNotaOrigem: null,
					diasParaExpirarCashback: 0,
					possuiDescontoPorTabela: false,
					valorDescontoRateadoDoItem: 0,
					itemDeDevolucao: false,
					isTotalmenteTrocado: false,
					motivoCancelamento: null,
					isCompra: false,
					idItemOrigem: '',
					isDaVendaReaberta: false,
					vendedor: null,
				}

				this.incluirItensNaTabela(item)
			} catch (error) {
				AlertModule.setError(error)
			} finally {
				this.buscandoProduto = false
				this.showCamera = true
			}
		}
	}

	async incluirItensNaTabela(item: ItemDaVenda) {
		const delay = ms => new Promise(res => setTimeout(res, ms));
		await delay(2000)

		this.loading = true

		if (!this.venda || !this.lojaDaVenda) {
			AlertModule.setError('Selecione uma loja ou venda!')
			this.loading = false
			return
		}

		try {
			const indiceDoItemBipado = this.venda.itens.findIndex(itemVenda => itemVenda.produto.sku === item.produto.sku)
			
			try {
				const itensComPrecoAplicado = await AplicarTabelaDePrecoUseCase({
					loja: this.lojaDaVenda,
					itens: [item],
					tipoDeCliente: this.venda.tipoDeCliente,
					validarLimiteDeProduto: true,
					vendaAtual: this.venda,
				})

				//itensComPrecoAplicado.forEach(itemComPrecoAplicado => itemComPrecoAplicado.desconto.valor = 0)

				if (indiceDoItemBipado === -1) {
					itensComPrecoAplicado.forEach(itemComPrecoAplicado => this.venda.itens.push(itemComPrecoAplicado))
				} else {
					this.venda.itens[indiceDoItemBipado].quantidade = this.venda.itens[indiceDoItemBipado].quantidade + item.quantidade
				}
			} catch (error) {
				AlertModule.setError(error)
				return
			}
		} catch (error) {
			AlertModule.setError(error)
		} finally {
			this.loading = false
		}
	}

	beep() {
		const audio = document.querySelector('audio')
		if(!audio) return 
		audio.play()
	}

	fecharDialogo(){
		this.mostra = false
		this.mostraConsultaDeProduto = false
		this.cancelar()
	}

	atualizarCliente(cliente: Cliente) {
		this.cpfOuCnpjDoCliente = formatarCnpjOuCpf(cliente.cnpjOuCpf)
		this.cpfOuCnpjDoClienteDoDialogo = this.cpfOuCnpjDoCliente
	}

	cancelar() {
		this.venda.itens = []
		this.venda.cliente = null
		this.venda.cpfDoCliente = null
		this.mostra = false
	}

	iniciarTroca() {
		this.venda.tipoDeTransacao = 'Outros'
		this.venda.tipoNota = 'Entrada'
		this.venda.itens.forEach(item => item.quantidade = -Math.abs(item.quantidade))
		this.mostra = false
		this.$emit('iniciarTroca')
	}
}

