



































import { Vue, Component, Prop, PropSync, Ref } from 'vue-property-decorator'
import { CreditosDoCliente, TipoDePagamento, Venda } from '@/models'
import CardDeTipoDePagamento from './CardDeTipoDePagamento.vue'
import { VendaModule } from '@/store/vuex/venda/VendaStore'

@Component({
	components: {
		CardDeTipoDePagamento,
	},
})
export default class MatrizDeTiposDePagamento extends Vue {
	@PropSync('value', { type: Object, default: null }) TipoDePagamento!: TipoDePagamento | null
	@PropSync('paginaAtual', { type: Number, default: 0 }) paginaAtualSync!: number
	@Prop({ type: Array, default: () => [] }) tiposDePagamento!: TipoDePagamento[]
	@Ref() cards!: CardDeTipoDePagamento[]
	@Prop() creditosDoCliente!: CreditosDoCliente
	@Prop({ type: Boolean }) desabilitarCartoes?: boolean

	created() {
		document.addEventListener('keydown', this.atalhoDeFormaDePagamento)
		document.addEventListener('keydown', this.atalhoDeOutrosTiposDePagamento)
	}

	destroyed() {
		document.removeEventListener('keydown', this.atalhoDeFormaDePagamento)
		document.removeEventListener('keydown', this.atalhoDeOutrosTiposDePagamento)
	}

	atalhoDeFormaDePagamento(event: KeyboardEvent) {
		if (event.altKey && event.key.toLowerCase().match(/[1-9]/)) {
			event.preventDefault()
			event.stopPropagation()

			const indice = Number(event.key) -1
			if (!this.tiposDePagamento[indice]) return

			const documentoAtivo: any | undefined = document.activeElement

			if (documentoAtivo?.blur) documentoAtivo.blur()
			if (!this.cards[indice]) return
			this.cards[indice].click()
		}
	}

	atalhoDeOutrosTiposDePagamento(event: KeyboardEvent) {
		if (event.altKey && event.key === 'o') {
			event.preventDefault()
			event.stopPropagation()
			this.irParaProximaPaginaDeTipos()
		}
	}

	irParaProximaPaginaDeTipos() {
		this.paginaAtualSync = (this.paginaAtualSync + 1) % this.totalDePaginas
	}

	get venda() {
		return VendaModule.vendaAtual as Venda
	}
	
	set venda(venda: Venda) {
		VendaModule.setVendaAtual(venda)
	}

	get totalDePaginas() {
		return Math.ceil(this.tiposDePagamento.length / this.pagamentosPorPagina)
	}

	get tiposDaPaginaAtual() {
		const inicio = this.paginaAtualSync * this.pagamentosPorPagina
		return this.venda.isConsignado && !this.venda.isVendaReaberta 
			? this.tiposDePagamento.slice(inicio, inicio + this.pagamentosPorPagina).filter(tipo => tipo.formaDePagamento === 'Consignado') 
			: this.tiposDePagamento.slice(inicio, inicio + this.pagamentosPorPagina)
	}

	get pagamentosPorPagina() {
		return this.$vuetify.breakpoint.mdAndUp
			? 9
			: 4
	}
	
	get possuiItemParaVenda() {
		return VendaModule.vendaAtual?.itens.some(item => item.isCompra) || false;
	}

	get temPagamentoQueGeraDuplicatas() {
		return VendaModule.vendaAtual?.pagamentos.some(pagamento => pagamento.tipoDePagamento.exibirNasDuplicatasDaDanfe) || false
	}

	get temPagamentoQueNaoGeraDuplicatas() {
		return VendaModule.vendaAtual?.pagamentos.some(pagamento => !pagamento.tipoDePagamento.exibirNasDuplicatasDaDanfe) || false
	}

	validarTipoPagamentoAtivo(tipoDePagamento: TipoDePagamento): boolean | undefined {
		if (typeof this.creditosDoCliente.voucher !== 'number' || typeof this.creditosDoCliente.crediario !== 'number') return undefined
		if (tipoDePagamento.formaDePagamento === 'Voucher' && !this.creditosDoCliente.voucher) return true
		if (tipoDePagamento.formaDePagamento === 'Crediário' && this.creditosDoCliente.crediario <= 0) return true
		if (tipoDePagamento.formaDePagamento === 'TEF Crédito' && !VendaModule.turnoDeVenda?.pontoDeVenda.loja.configuracaoDaLoja?.integracaoControlPayAtiva) return true
		if (tipoDePagamento.formaDePagamento === 'TEF Débito' && !VendaModule.turnoDeVenda?.pontoDeVenda.loja.configuracaoDaLoja?.integracaoControlPayAtiva) return true
		if (this.desabilitarCartoes && tipoDePagamento.formaDePagamento === 'Cartão') return false
		if (tipoDePagamento.formaDePagamento === 'Consignado' && !VendaModule.vendaAtual?.isConsignado) return true
		if (tipoDePagamento.formaDePagamento === 'Demonstração' && !VendaModule.vendaAtual?.isDemonstracao) return true
		if(tipoDePagamento.formaDePagamento === 'Demonstração' && 
			VendaModule.vendaAtual?.isVendaReaberta &&
				VendaModule.vendaAtual?.isDemonstracao && this.possuiItemParaVenda) return true
		if((this.temPagamentoQueGeraDuplicatas && !tipoDePagamento.exibirNasDuplicatasDaDanfe) || 
		(this.temPagamentoQueNaoGeraDuplicatas && tipoDePagamento.exibirNasDuplicatasDaDanfe)) return true

		return false
	}

	obterValorDisponivel(tipoDePagamento: TipoDePagamento): number | undefined {
		if (tipoDePagamento.formaDePagamento === 'Voucher') return this.creditosDoCliente.voucher
		if (tipoDePagamento.formaDePagamento === 'Crediário') return this.creditosDoCliente.crediario
		return undefined
	}

	async input(tipoDePagamento: TipoDePagamento) {
		this.$emit('input', tipoDePagamento)
	}
}

