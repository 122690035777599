


























































































import AlterarPrecosDaVendaPorCliente from '@/usecases/venda/AlterarPrecosDaVendaPorCliente'
import DialogoDeEdicaoDeCliente from '@/components/cliente/DialogoDeEdicaoDeCliente.vue'
import CampoDeCpfOuCnpjOculto from '@/components/ui/CampoDeCpfOuCnpjOculto.vue'
import { Vue, Component, Ref, Watch, PropSync } from 'vue-property-decorator'
import SeletorDePessoa from '@/components/cliente/SeletorDePessoa.vue'
import UserLoginStore from '@/store/vuex/authentication/UserLoginStore'
import PluginModule from '@/store/vuex/aplicacao/PluginStore'
import AlertModule from '@/store/vuex/aplicacao/AlertModule'
import { VendaModule } from '@/store/vuex/venda/VendaStore'
import { criarCliente } from '@/shareds/cliente-shareds'
import { obrigatorio } from '@/shareds/regras-de-form'
import { FindClienteUseCase, FindConfiguracaoDaContaUseCase, SaveLojaUseCase } from '@/usecases'
// import { isVestcasa } from '@/shareds/utils'
import { Cliente, FormCliente } from '@/models'
import DialogoDeAniversarioDoCliente from './DialogoDeAniversarioDoCliente.vue'
import { removerFormatacaoDeCnpjOuCpf } from '@/shareds/formatadores'
import axios from 'axios'
import BotaoDeConsultaCliente from './BotaoDeConsultaCliente.vue'

@Component({
	components: {
		DialogoDeEdicaoDeCliente,
		CampoDeCpfOuCnpjOculto,
		DialogoDeAniversarioDoCliente,
		SeletorDePessoa,
		BotaoDeConsultaCliente,
	},
})
export default class CampoDeClienteNoCaixa extends Vue {
	@Ref() campoDeCliente!: SeletorDePessoa | CampoDeCpfOuCnpjOculto
	@Ref() trocadorDeUtilizarApenasCpf!: { $el: HTMLDivElement }
	@Ref() dialogoEdicaoDeCliente!: DialogoDeEdicaoDeCliente
	@Ref() dialogoDeAniversarioDoCliente!: DialogoDeAniversarioDoCliente
	@PropSync('value') cpfOuCnpjDoCliente!: string

	mostraTrocarUtilizarCpf: boolean | null = null
	findClienteUseCase = new FindClienteUseCase()
	saveLojaUseCase = new SaveLojaUseCase()
	findConfiguracaoDaContaUseCase = new FindConfiguracaoDaContaUseCase()
	utilizarApenasCpf = true
	// TEMPORARIO POR EDICAO BUGADA DE COMPONENTE
	// utilizarApenasCpf = isVestcasa
	obrigatorio = obrigatorio
	isVestcasa = true
	// TEMPORARIO POR EDICAO BUGADA DE COMPONENTE
	// isVestcasa = isVestcasa
	buscandoCliente = false
	cliente: Cliente | null = null
	existeVinculo: boolean | false = false

	async created() {
		this.clienteAtualizadoNaVenda
	}

	async mostrarDialogoDeAniversario(){
		this.dialogoDeAniversarioDoCliente.mostrar
	}

	mostrarDialogoDeCliente() {
		if (!this.venda) return
		const cliente: FormCliente = !this.venda.cliente
			? criarCliente()
			: JSON.parse(JSON.stringify(this.venda.cliente))

		const indiceDaLoja = cliente.lojas.findIndex(loja => loja.id === this.venda?.loja)
		if (indiceDaLoja === -1 && VendaModule.lojaDaVenda) {
			cliente.lojas.push(VendaModule.lojaDaVenda)
		}
		
		cliente.cnpjOuCpf = !cliente.cnpjOuCpf && this.venda.cpfDoCliente ? this.venda.cpfDoCliente : cliente.cnpjOuCpf
		this.dialogoEdicaoDeCliente.mostrar(cliente, !cliente.id)
	}

	atualizarClienteDaVenda(cliente: Cliente) {
		this.cpfOuCnpjDoCliente = cliente.cnpjOuCpf
		this.aplicarClienteNaVenda(cliente)
		this.cliente = cliente
		this.$emit('atualizarCliente', this.cliente)
	}

	async clienteAtualizadoNaVenda(){
		if (!this.venda || !this.venda.cliente) return
		const params = {
			gruposEconomicosId: [VendaModule.lojaDaVenda?.grupoEconomico.id],
		}
		const axiosConfig = {
			cancelToken: axios.CancelToken.source().token,
		}
		const clienteAtualizado = await this.findClienteUseCase.get(this.venda.cliente.id, params, axiosConfig)
		this.associarClienteNaVenda(clienteAtualizado)
	}

	associarClienteNaVenda(cliente: Cliente) {
		if (!cliente) return
		this.aplicarClienteNaVenda(cliente, true)
	}

	focus() {
		this.campoDeCliente.focus()
	}

	get venda() {
		return VendaModule.vendaAtual
	}

	get turnoDeVenda() {
		return VendaModule.turnoDeVenda
	}

	get usuario() {
		return UserLoginStore.usuario
	}

	get utilizandoCampoDeCpf() {
		return this.componenteDeCliente === 'CampoDeCpfOuCnpjOculto'
	}

	get obrigatorioInformarCliente() {
		return this.venda?.tipoDeTransacao === 'Devolução' || this.turnoDeVenda?.pontoDeVenda.criaPedidoNaVenda
	}

	get componenteDeCliente() {
		return !this.isVestcasa && this.venda && (!this.utilizarApenasCpf || this.venda.tipoDeTransacao === 'Devolução')
			? 'SeletorDePessoa'
			: 'CampoDeCpfOuCnpjOculto'
	}

	get infosCliente() {
		return VendaModule.informacoesDoCliente
	}

	set infosCliente(informacoes: typeof VendaModule['informacoesDoCliente']) {
		VendaModule.setInformacoesDoCliente(informacoes)
	}

	get clienteHint() {
		if (!this.infosCliente) return ''
		return this.infosCliente.mensagem
	}

	get obterIconeDoCampo() {
		return this.venda && this.utilizandoCampoDeCpf
			? this.venda.cliente ? 'mdi-pencil' : 'mdi-plus'
			: undefined
	}

	get carregandoOsItensDaVenda() {
		return VendaModule.carregandoItensDaVenda
	}

	@Watch('cpfOuCnpjDoCliente', { immediate: true })
	async onChangeCliente(cpfOuCnpj: string | null, cpfOuCnpjAnterior: string | null) {
		if (cpfOuCnpjAnterior === undefined) return
		if (removerFormatacaoDeCnpjOuCpf(cpfOuCnpj) === removerFormatacaoDeCnpjOuCpf(cpfOuCnpjAnterior)) return
		this.aplicarClienteNaVenda(cpfOuCnpj)
	}

	get recarregaVenda() {
		return VendaModule.recarregaVenda
	}

	async aplicarClienteNaVenda(cliente: Cliente | string | null, forcarReconsulta = false) {
		try {
			await AlterarPrecosDaVendaPorCliente({
				cliente,
				onAtualizarCarregando: (buscando) => this.buscandoCliente = buscando,
				forcarReconsulta,
				validarLimiteDeProduto: true,
			})
			
			// if necessário para evitar disparar evento durante a digitação do CPF
			if (this.buscandoCliente === true) {
				
				if (typeof cliente === 'string') {
					const cpfOuCnpjFormatado = removerFormatacaoDeCnpjOuCpf(cliente)
					const params = {
						gruposEconomicosId: [VendaModule.lojaDaVenda?.grupoEconomico.id],
					}
					const axiosConfig = {
						cancelToken: axios.CancelToken.source().token,
					}
					this.cliente = await this.findClienteUseCase.get(cpfOuCnpjFormatado, params, axiosConfig)
				}

				this.existeVinculo = this.venda && this.venda.pontoDeVenda &&
				this.venda.pontoDeVenda.loja && this.cliente && this.cliente.id
					? await this.findClienteUseCase.existeVinculoDeClinteELoja(this.cliente.id, this.venda.pontoDeVenda.loja.id)
					: false
				
				const confDeConta = await this.findConfiguracaoDaContaUseCase.find()
				
				if (!this.existeVinculo && this.venda && this.venda.pontoDeVenda && this.cliente && this.cliente.id && confDeConta.vincularClientesComLoja) {
					await this.saveLojaUseCase.vincularClientesComLoja(this.venda?.pontoDeVenda?.loja.id, [this.cliente])
				}

				PluginModule.emit('aplicarClienteNaVenda', { venda: this.venda, usuario: this.usuario })
				
			}
			const dataFormatada = new Date().toISOString().split('T')[0]
			const dataDia = dataFormatada.split('-')[2]
			const dataMes = dataFormatada.split('-')[1]
			const aniversario = this.venda?.cliente?.dataNascimento ? this.venda?.cliente?.dataNascimento.split('-') : []

			if(aniversario[2] == dataDia && aniversario[1] == dataMes){
				this.dialogoDeAniversarioDoCliente.mostrar()
			}

			const cpfOuCnpjFormatado: string = typeof cliente !== 'string'
				? cliente?.cnpjOuCpf || ''
				: cliente

			const venda = VendaModule.vendaAtual

			if (venda) {
				const cpfOuCnpj = removerFormatacaoDeCnpjOuCpf(cpfOuCnpjFormatado)
				if (!forcarReconsulta && cpfOuCnpj === venda.cpfDoCliente && VendaModule.informacoesDoCliente) return

				venda.cpfDoCliente = cpfOuCnpj

				VendaModule.setVendaAtual(venda)
			}

		} catch (error) {
			AlertModule.setError(error)
			if (AlertModule.getErrorMsg === 'Cliente já esgotou limite de compras desse produto no dia atual') {
				const permissaoConcedida = await VendaModule.autenticarAcaoTelaDeCaixa({ regra:'pode-alterar-quantidade-de-produto' })
				if (!permissaoConcedida) return 
				await AlterarPrecosDaVendaPorCliente({
					cliente,
					onAtualizarCarregando: (buscando) => this.buscandoCliente = buscando,
					forcarReconsulta,
					validarLimiteDeProduto: false,
				})
			}
		} finally {
			this.buscandoCliente = false
		}
	}

	@Watch('buscandoCliente')
	onChangeBuscandoCliente() {
		this.$emit('update:buscandoCliente', this.buscandoCliente)
	}
	
	@Watch("utilizarApenasCpf")
	onChangeUtilizarApenasCpf() {
		this.mostraTrocarUtilizarCpf = false
		if (!this.venda) return
		this.venda.cpfDoCliente = null
		this.venda.cliente = null
	}

	@Watch('mostraTrocarUtilizarCpf')
	onChangeMostraTrocarUtilizarCpf(mostra: boolean) {
		if (!mostra) {
			this.$nextTick(() => {
				this.focus()
			})
			return
		}
		setTimeout(() => {
			this.trocadorDeUtilizarApenasCpf.$el.focus()
		}, 125)
	}
}

