import { container } from 'inversify-props'
import { VendaServiceAdapter } from './adapter'
import { ReturnedPromiseResolvedType } from '@/models/@types/utils'
import { AmbienteFiscal } from '@/models/SerieFiscal'

type UseCaseParams = {
	ambiente?: AmbienteFiscal
	service?: VendaServiceAdapter
	identificador?: string
	idLoja?: string
	numeroDaNota?: string
	serieFiscal?: string
	grupoEconomicoId?: string
}

export default async ({
	service = container.get<VendaServiceAdapter>('VendaServiceAdapter'),
	ambiente,
	identificador,
	idLoja,
	numeroDaNota,
	serieFiscal,
	grupoEconomicoId, 
}: UseCaseParams) => {
	let resultado: ReturnedPromiseResolvedType<typeof service['find']>
	if (identificador) {
		resultado = await service.find({ identificador, isBuscaParaDevolucao: true, lojaId: idLoja, grupoEconomicoId: grupoEconomicoId })
	} else {
		if (!idLoja) throw new Error('Loja não informada')
		if (!numeroDaNota) throw new Error('Número da Nota não informado')
		if (!serieFiscal) throw new Error('Série Fiscal não informada')
		resultado = await service.find({
			tipoAmbiente: ambiente,
			lojaId: idLoja,
			numeroNota: numeroDaNota,
			serie: serieFiscal,
		})
	}
	const venda = resultado.content[0]
	if (!venda) throw new Error('Venda não encontrada')
	if (venda.situacao === 'Cancelada') throw new Error('Venda está cancelada, não é possível devolver')
	if (venda.tipoDeTransacao === 'Devolução') throw new Error('Não pode devolver uma devolução')
	if (!venda.itens.length) throw new Error('Todos os itens já foram devolvidos. Não é possível fazer a troca')
	return venda
}
