import { FormaDePagamento, FormPagamento } from '@/models'

export const listaDeFormasDePagamento: FormaDePagamento[] = [
	'Dinheiro',
	'Cartão',
	'Voucher',
	'Crediário',
	'TEF Crédito',
	'TEF Débito',
	'TEF Pix',
	'Consignado',
	'Demonstração',
	'Link',
	'Brinde',
	'Outros',
]

export function criarFormPagamento(): FormPagamento {
	return {
		valor: null,
		tipoDePagamento: null,
		dataHoraPagamento: null,
		qtdeDeParcelas: 0,
		status: null,
		valorDaTaxa: 0,
		detalhesDoPagamentoId: null,
		link: null,
	}
}
