



















































































































































































































































































import { Vue, Component, Ref, Watch, Prop } from 'vue-property-decorator'
import { formatarCnpjOuCpf, formatarMoeda } from '@/shareds/formatadores'
import CamposDeItemDaVenda from './CamposDeItemDaVenda.vue'
import { Venda, ItemDaVenda, Perfil, Cliente, Comanda } from '@/models'
import {
	obterTotalDaVenda,
	obterTotalDoItem,
	contarItensDaVenda,
	obterSubTotalDoItem,
	obterTotalDosItensDaVenda,
	unificarItensDaVendaPorProduto,
} from '@/shareds/venda-shareds'
import UserLoginStore from '../../../store/vuex/authentication/UserLoginStore'
import BotaoDeDesconto from '@/views/application/venda/BotaoDeDesconto.vue'
import AlertModule from '@/store/vuex/aplicacao/AlertModule'
import Confirmacao from '@/components/ui/Confirmacao.vue'
import { VendaModule } from '@/store/vuex/venda/VendaStore'
import {
	displayNomeCompletoDoProduto,
	displayVariantes,
} from '@/shareds/produto-shareds'
import { isValidCNPJ, isValidCPF } from '@brazilian-utils/brazilian-utils'
import { ComandaUseCase, FindEstoqueUseCase, VendaServiceAdapter } from '@/usecases'
import CampoDeClienteNoCaixa from './CampoDeClienteNoCaixa.vue'
import { nextTick, validarCpfOuCnpj } from '@/shareds/utils'
import { obrigatorio } from '@/shareds/regras-de-form'
import DialogoDeInformarVendedor from './DialogoDeInformarVendedor.vue'
import { Inject } from 'inversify-props'
import AplicarTabelaDePrecoUseCase from '@/usecases/tabela-de-precos/AplicarTabelaDePrecoUseCase'
import { v4 as uuidv4 } from 'uuid'
import { PERFIL_LOGADO } from '@/common/constants'
import { obterDescontoDaVenda } from '@/shareds/venda-shareds'

@Component({
	components: {
		CamposDeItemDaVenda,
		BotaoDeDesconto,
		Confirmacao,
		CampoDeClienteNoCaixa,
		DialogoDeInformarVendedor,
	},
})
export default class CardDeCompras extends Vue {
	@Prop({ type: Boolean }) pedindoCliente!: boolean
	@Prop({ type: Boolean }) loadingPagar!: boolean
	@Prop({ type: Boolean }) disabledPagar!: boolean
	@Prop({ type: Boolean, default: false}) permissaoConcedida!: boolean
	@Prop({ type: Boolean }) vendaComErro!: boolean
	@Ref() formVenda!: HTMLFormElement
	@Ref() panelHeaders!: { $el: HTMLElement }[]
	@Ref() camposDeVenda!: CamposDeItemDaVenda[]
	@Ref() botaoDePagamento!: { $el: HTMLButtonElement }
	@Ref() botaoDeFechamentoDeCaixa!: { $el: HTMLButtonElement }
	@Ref() botaoDeCancelamentoDeVenda!: { $el: HTMLButtonElement }
	@Ref() campoDeCliente!: CampoDeClienteNoCaixa
	@Ref() formsDeCamposDaVenda!: HTMLFormElement[]
	@Ref() painelExpansao!: HTMLDivElement
	@Ref() formDoDialogoDeCliente!: HTMLFormElement
	@Ref() campoDeClienteDoDialogo!: CampoDeClienteNoCaixa
	@Ref() dialogoDeVendedor!: DialogoDeInformarVendedor

	displayVariantes = displayVariantes
	formatarMoeda = formatarMoeda
	displayNomeCompletoDoProduto = displayNomeCompletoDoProduto
	obterSubTotalDoItem = obterSubTotalDoItem
	obterTotalDoItem = obterTotalDoItem
	contarItensDaVenda = contarItensDaVenda

	painel: number | null = null
	zIndexPagamentoEmAndamento = 13 // para o botão ficar em cima do overlay quando o pagamento ja foi inicializado
	menuAtivo: number[] = []
	buscandoCliente = false
	obrigatorio = obrigatorio
	item!: ItemDaVenda		

	@Inject('VendaServiceAdapter')
	private vendaService!: VendaServiceAdapter

	findEstoqueUseCase = new FindEstoqueUseCase()
	comandaUseCase = new ComandaUseCase()

	cpfOuCnpjDoCliente: null | string =
		VendaModule.vendaAtual && VendaModule.vendaAtual.cpfDoCliente
			? formatarCnpjOuCpf(VendaModule.vendaAtual.cpfDoCliente)
			: null
	cpfOuCnpjDoClienteDoDialogo = this.cpfOuCnpjDoCliente

	get obrigatorioInformarCliente() {
		return (
			this.venda?.tipoDeTransacao === 'Devolução' ||
			this.venda.tipoDeTransacao === 'Outros' ||
			this.turnoDeVenda?.pontoDeVenda.criaPedidoNaVenda ||
			this.turnoDeVenda?.pontoDeVenda.exigeCliente
		)
	}

	@Watch('venda.cpfDoCliente', { immediate: true })
	onChangeCpf() {
		this.cpfOuCnpjDoCliente =
		VendaModule.vendaAtual && VendaModule.vendaAtual.cpfDoCliente
			? formatarCnpjOuCpf(VendaModule.vendaAtual.cpfDoCliente)
			: null
	}

	get vendaEDemonstracaoOuConsignado() {
		return this.venda.isVendaReaberta &&
			(this.venda.isConsignado || this.venda.isDemonstracao)
	}

	get exigeClienteCadastrado() {		
		return VendaModule.turnoDeVenda?.pontoDeVenda.exigeClienteCadastrado
	}	

	atualizarCliente(cliente: Cliente) {
		this.cpfOuCnpjDoCliente = formatarCnpjOuCpf(cliente.cnpjOuCpf)
		this.cpfOuCnpjDoClienteDoDialogo = this.cpfOuCnpjDoCliente
		this.venda.cliente = cliente
	}

	async validarSeVendaExigeCpf() {
		if(!this.loja) return 
		if(!this.venda) return
		if(this.venda.isConsignado && !this.venda.cliente) return AlertModule.setError('Venda consignada exige cpf/cnpj do cliente')
		if(this.venda.isDemonstracao && !this.venda.cliente) return AlertModule.setError('Venda de demonstração exige cpf/cnpj do cliente')
		if(this.venda.isBrinde && !this.venda.cliente) return AlertModule.setError('Venda brinde exige cpf/cnpj do cliente')
		if(this.total > 10000 && !this.venda.cliente) return AlertModule.setError('Venda com o valor acima de 10 mil é necessário informar um cliente')
		if(this.exigeClienteCadastrado && !this.venda.cliente?.cnpjOuCpf) return AlertModule.setError('Obrigatório informar um cliente cadastrado!')
		const vendaPossuiItem = this.venda.itens.filter(item => item.quantidade > 0)

		if(vendaPossuiItem.length === 0 && this.venda.tipoDeTransacao === 'Venda' &&
		this.venda.isConsignado && !this.vendaEDemonstracaoOuConsignado) return AlertModule.setError('Venda consignada exige pelo menos um item')
		if(vendaPossuiItem.length === 0 && this.venda.tipoDeTransacao === 'Venda' &&
		this.venda.isDemonstracao && !this.vendaEDemonstracaoOuConsignado) return AlertModule.setError('Venda de demonstração exige pelo menos um item')

		const tipoDeCliente = this.venda && this.venda.tipoDeCliente
			? this.venda.tipoDeCliente
			: null

		if(this.venda.cpfDoCliente && !validarCpfOuCnpj(this.venda.cpfDoCliente)) {
			AlertModule.setError('CPF ou CPNJ informado é inválido')
			return
		}

		if(this.venda.tipoDeTransacao !== 'Devolução' && this.venda.tipoDeTransacao !== 'Outros' && (this.venda.desconto && this.venda.desconto.valor > 0 
				|| this.venda.itens.filter(item => item.desconto && item.desconto.valor > 0).length > 0) 
				&& (!(this.venda.isVendaReaberta && (this.venda.isConsignado || this.venda.isDemonstracao)) || (this.venda.itens.filter(item => item.isCompra).length > 0))) {

			let valorBruto = 0
			let valorTotalDesconto = 0
			let percentualDeDescontoDaVenda = 0
			const valorDescontoCapa = obterDescontoDaVenda({
				...this.venda,
				desconto: this.venda.desconto,
			})
			if(this.venda.isDemonstracao || this.venda.isConsignado) {
				const itensDaVenda = this.venda.itens.filter(item => item.isCompra)
				itensDaVenda.forEach(item => {
					const valorLiquidoItem = this.validarTotalDoItem(item)
					const valorBrutoItem = this.obterSubTotalDoItem(item)

					if (item.possuiDescontoPorTabela) {
						valorBruto = valorBruto + valorLiquidoItem
					} else {
						valorBruto = valorBruto + valorBrutoItem
						valorTotalDesconto = Math.round((valorTotalDesconto + (valorBrutoItem - valorLiquidoItem)) * 100) / 100
					}
				})
			} else {
				this.venda.itens.forEach(item => {
					const valorLiquidoItem = this.validarTotalDoItem(item)
					const valorBrutoItem = this.obterSubTotalDoItem(item)

					if (item.possuiDescontoPorTabela) {
						valorBruto = valorBruto + valorLiquidoItem
					} else {
						valorBruto = valorBruto + valorBrutoItem
						valorTotalDesconto = Math.round((valorTotalDesconto + (valorBrutoItem - valorLiquidoItem)) * 100) / 100
					}
				})
			}

			valorTotalDesconto = valorTotalDesconto + valorDescontoCapa

			valorBruto = Math.round(valorBruto* 100) / 100
			valorTotalDesconto = Math.round(valorTotalDesconto* 100) / 100
			

			const perfil: null | Perfil = localStorage[PERFIL_LOGADO]
				? JSON.parse(localStorage[PERFIL_LOGADO])
				: null
			
			if (!perfil) {
				AlertModule.setError('Perfil não encontrado')
				return
			}

			if (!this.podeConcederDesconto) {
				AlertModule.setError('Sem permissão para conceder desconto')
				return
			}

			if (this.podeConcederDesconto && perfil.percentualMaximoDesconto > 0) {
				const cashback = this.venda.valorDeCashbackAplicado
				const percentualCashback = (cashback / valorBruto) * 100;
				
				percentualDeDescontoDaVenda = Math.round(((valorTotalDesconto/valorBruto)*100) * 100) / 100
				
				percentualDeDescontoDaVenda = percentualDeDescontoDaVenda - percentualCashback

				const valorPermitidoDeDesconto = valorBruto*perfil.percentualMaximoDesconto/100
				if (percentualDeDescontoDaVenda > perfil.percentualMaximoDesconto) {
					AlertModule.setError('Sem permissão para conceder mais de ' + perfil.percentualMaximoDesconto + '% de desconto na venda. Você pode conceder apenas R$' + formatarMoeda(valorPermitidoDeDesconto) + ' de desconto')
					return
				}
			}
		}

		const indice = this.formsDeCamposDaVenda.findIndex(form => !form.validate())
		if (indice !== -1) {
			this.painel = indice
			setTimeout(() => {
				this.panelHeaders[indice].$el.scrollIntoView()
			}, 300)
			return
		}

		if (
			this.venda.cpfDoCliente &&
			!isValidCPF(this.venda.cpfDoCliente) &&
			!isValidCNPJ(this.venda.cpfDoCliente)
		) {
			AlertModule.setError('Informe um CPF / CNPJ válido')
			return
		}

		if (this.venda.tipoDeTransacao === 'Venda' && this.totalDaVenda <= 0 && !this.vendaEDemonstracaoOuConsignado) {
			AlertModule.setError(
				'Valor do desconto não pode ser maior que o valor do item',
			)
			return
		}

		if (
			(this.obrigatorioInformarCliente) &&
			!this.venda.cpfDoCliente?.length
		) {
			AlertModule.setError('Informe um CPF ou CNPJ')
			return
		}
		if (
			(this.venda.tipoDeTransacao === 'Devolução' || this.venda.tipoDeTransacao === 'Outros' ||
				this.venda.pedido) &&
			!this.venda.cliente?.id
		) {
			AlertModule.setError('Cliente não cadastrado no sistema')
			return
		}

		if (this.venda.pedido && !this.venda.cliente?.telefones.length) {
			AlertModule.setError('Cadastre um telefone para o cliente informado')
			return
		}

		if (
			(this.turnoDeVenda?.pontoDeVenda.loja.configuracaoDaLoja
				?.podeGerarEstoqueNaVenda &&
				!this.turnoDeVenda?.pontoDeVenda.criaPedidoNaVenda) ||
			this.venda.tipoDeTransacao === 'Devolução' ||
			this.venda.tipoDeTransacao === 'Outros'
		) {
			this.$emit('pagar', this.venda)
			return
		}

		if (!this.formVenda.validate()) {
			AlertModule.setError('Verique os dados cadastrados')
			return
		}

		const itens = this.venda.itens
		try {
			await AplicarTabelaDePrecoUseCase({
				loja: this.loja,
				itens,
				tipoDeCliente,
				validarLimiteDeProduto: false,
				vendaAtual: this.venda,
			})

			if (!this.vendaComErro) {
				const identificador = await this.vendaService.validarIdentificadorDeVenda(this.turnoDeVenda?.pontoDeVenda.id || '', this.venda.identificador || '')

				if (this.venda.identificador != identificador ) {
					this.venda.id = uuidv4()
					this.venda.identificador = identificador
				}
			}

			const itensUnificados = unificarItensDaVendaPorProduto(this.venda.itens)
			await Promise.all(itensUnificados)

			this.$emit('pagar', this.venda)
		} catch(error) {
			AlertModule.setError(error)
			return
		}
	}

	get cadastroDoClienteIncompleto() {
		return () =>
			!this.venda ||
			!!this.venda.cliente ||
			'Favor completar o cadastro do cliente'
	}

	get venda() {
		return VendaModule.vendaAtual as Venda
	}

	set venda(venda: Venda) {
		VendaModule.setVendaAtual(venda)
	}

	get turnoDeVenda() {
		return VendaModule.turnoDeVenda
	}

	get temVendaComPagamentos() {
		return VendaModule.temVendaComPagamentos
	}

	get total() {
		return obterTotalDaVenda(this.venda)
	}

	get subtotal() {
		return obterTotalDosItensDaVenda(this.venda)
	}

	get loja() {
		return VendaModule.lojaDaVenda
	}

	get recarregaVenda() {
		return VendaModule.recarregaVenda
	}

	set recarregaVenda(recarregaVenda: boolean) {
		VendaModule.setRecarregaVenda(recarregaVenda)
	}

	get totalDaVenda() {
		return this.validarTotalDaVenda(this.venda)
	}

	get devolucaoParcial() {
		return !this.loja?.configuracaoDaLoja.devolucaoParcial
	}

	created() {
		document.addEventListener('keydown', this.atalhoDeUltimaVenda)
		document.addEventListener('keydown', this.atalhoDePagar)
		document.addEventListener('keydown', this.atalhoDeNovaVenda)
		document.addEventListener('keydown', this.atalhoDeClienteOuCpf)
		this.$emit('update:pedindoCliente', VendaModule.lembraDePedirOCliente)
	}

	mounted() {
		this.focarNaTela()
	}

	async focarNaTela() {
		await nextTick()
		if (!this.pedindoCliente) {
			this.focarCampoDaBuscaDeProduto()
		} else {
			this.campoDeClienteDoDialogo.focus()
		}
	}

	pedirCliente() {
		this.$emit('update:pedindoCliente', true)
	}

	destroyed() {
		document.removeEventListener('keydown', this.atalhoDeUltimaVenda)
		document.removeEventListener('keydown', this.atalhoDePagar)
		document.removeEventListener('keydown', this.atalhoDeNovaVenda)
		document.removeEventListener('keydown', this.atalhoDeClienteOuCpf)
	}

	atalhoDeClienteOuCpf(event: KeyboardEvent) {
		if (event.altKey && event.key.toLowerCase() === 'c') {
			event.preventDefault()
			this.campoDeCliente.focus()
		}
	}

	validarTotalDoItem(item: ItemDaVenda) {
		let totalDoItem = obterTotalDoItem(item)
		if (this.venda.tipoDeTransacao === 'Devolução' || this.venda.tipoDeTransacao === 'Outros') {
			return totalDoItem
		} else if (totalDoItem < 0) {
			return (totalDoItem = 0)
		}
		return totalDoItem
	}

	validarTotalDaVenda(venda: Venda) {
		let totalDaVenda = obterTotalDaVenda(venda)
		if (this.venda.tipoDeTransacao === 'Devolução' || this.venda.tipoDeTransacao === 'Outros') {
			return totalDaVenda
		} else if (totalDaVenda < 0) {
			return (totalDaVenda = 0)
		}
		return totalDaVenda
	}

	validarSubTotalDaVenda(venda: Venda) {
		let subTotalDaVenda = obterTotalDosItensDaVenda(venda)
		if (this.venda.tipoDeTransacao === 'Devolução' || this.venda.tipoDeTransacao === 'Outros') {
			return subTotalDaVenda
		} else if (subTotalDaVenda < 0) {
			return (subTotalDaVenda = 0)
		}
		return subTotalDaVenda
	}

	atalhoDePagar(event: KeyboardEvent) {
		if (event.altKey && event.key.toLowerCase() === 'r') {
			event.preventDefault()
			this.botaoDePagamento.$el.click()
		}
	}

	colorItem(item: any) {
		if(this.venda.isConsignado) return ['yellow lighten-4']
		if(item.isBrinde) return ['green lighten-4']			
		if(item.idConjunto !== null && item.idConjunto !== '' && !item.isCompra) return ['primary lighten-4']
		if(this.venda.isVendaReaberta && this.venda.isDemonstracao && item.isCompra)  return ['light green accent-5']
		if(this.venda.isDemonstracao) return ['grey']
		return ['white']
	}

	podeVenderItem(item) {
		return (
			this.venda.isVendaReaberta &&
			(this.vendaEDemonstracaoOuConsignado) &&
			item.quantidade > 0 &&
			(item.idConjunto && item.idConjunto.length > 0 || !item.idConjunto)
		)
	}

	async alterouSwitch(item, indice) {
		if (!this.loja) return

		if (!item.isCompra) {
			const itens = [item]
			try {
				const itemComPrecoOriginal = await AplicarTabelaDePrecoUseCase({
					loja: this.loja,
					itens,
					tipoDeCliente: this.venda.tipoDeCliente,
					validarLimiteDeProduto: false,
					vendaAtual: this.venda,
				})

				this.venda.itens.splice(indice, 1, itemComPrecoOriginal[0])
			} catch(error) {
				AlertModule.setError(error)
				return
			}
		}
	}

	atalhoDeUltimaVenda(event: KeyboardEvent) {
		if (this.panelHeaders && !this.panelHeaders.length) return
		if (event.altKey && event.key.toLowerCase() === 'u') {
			event.preventDefault()
			this.panelHeaders[this.panelHeaders.length - 1].$el.click()
			setTimeout(() => {
				this.camposDeVenda[this.camposDeVenda.length - 1].focus()
			})
		}
	}

	atalhoDeNovaVenda(event: KeyboardEvent) {
		if (!this.podeCancelarVenda) return
		if (event.altKey && event.key.toLowerCase() === 'n') {
			event.preventDefault()
			this.botaoDeCancelamentoDeVenda.$el.click()
		}
	}

	async validarPagamentos() {

		if (!await VendaModule.autenticarAcao({ regra: 'pode-excluir-item-de-venda' })) return
		
		try {
			const pagamentosPorTef = VendaModule.vendaAtual?.pagamentos.filter(
				pagamento =>
					pagamento.tipoDePagamento.formaDePagamento === 'TEF Crédito' ||
					pagamento.tipoDePagamento.formaDePagamento === 'TEF Débito' ||
					pagamento.tipoDePagamento.formaDePagamento === 'TEF Pix'
			)

			if (pagamentosPorTef && pagamentosPorTef?.length > 0)
				throw new Error(
					'Existe pagamento(s) por tef autorizado(s) na venda atual, não é possível iniciar uma nova venda.',
				)

			this.iniciarNovaVenda()
		} catch (error) {
			AlertModule.setError(error)
			return
		}
	}

	async iniciarNovaVenda() {
		const identificador = await this.vendaService.gerarIdentificadorDeVenda(
			this.turnoDeVenda?.pontoDeVenda.id || '',
		)
		await VendaModule.iniciarNovaVenda({ identificador, novaVendaReaberta: false })
		this.$emit('cancelar')
		this.$emit(
			'update:pedindoCliente',
			this.loja?.configuracaoDaLoja.lembreteDeCpfCnpj || false,
		)
		
		const comandaJson = localStorage["COMANDA_IMPORTADA"]
		const comanda: Comanda | null = comandaJson ? JSON.parse(comandaJson) : null

		if(comanda) {
			const comandaSalvar: Comanda = {
				id: comanda.id,
				identificador: comanda.identificador,
				dataHoraDeAbertura: comanda.dataHoraDeAbertura,
				aberto: true,
				nome: comanda.nome,
				descricao: '',
				itemDaComanda: comanda.itemDaComanda,
				createdAt: comanda.createdAt,
				updatedAt: comanda.updatedAt,
				taxaDeServico: 0,
				aguardandoProcessamentoNoCaixa: false,
			}

			await this.comandaUseCase.save(comandaSalvar)
		}
		
		this.cpfOuCnpjDoCliente = null
		this.cpfOuCnpjDoClienteDoDialogo = null
		if (this.venda.pontoDeVenda?.exigeVendedor) {
			this.dialogoDeVendedor.mostrar()
		}
		this.vendaComErro = false
		this.focarNaTela()
	}

	focarCampoDaBuscaDeProduto() {
		if (
			this.venda.cpfDoCliente &&
			(isValidCPF(this.venda.cpfDoCliente) ||
				isValidCNPJ(this.venda.cpfDoCliente))
		) {
			this.$emit('focarCampoDeBusca')
		}
	}

	get podeExcluirItemDeVenda() {
		return UserLoginStore.permiteRegraDeNegocio('pode-excluir-item-de-venda')
	}

	get podeCancelarVenda() {
		return UserLoginStore.permiteRegraDeNegocio('pode-cancelar-venda')
	}

	get podeConcederDesconto() {
		return UserLoginStore.permiteRegraDeNegocio('pode-conceder-desconto')
	}

	async removerItemDaVenda(indice: number) {
		if (indice <= -1) return

		if (this.venda.itens[indice].idConjunto !== undefined && this.venda.itens[indice].idConjunto !== null) {
			const itemDeletado = this.venda.itens[indice]

			while (
				this.venda.itens.some(
					item => item.idConjunto === itemDeletado.idConjunto,
				)
			) {
				const itemIndice = this.venda.itens.findIndex(
					produto => produto.idConjunto === itemDeletado.idConjunto,
				)
				this.venda.itens.splice(itemIndice, 1)
			}
		} else {
			this.venda.itens.splice(indice, 1)
		}

		VendaModule.setVendaAtual(this.venda)

		if (
			this.venda.itens.length == 0 &&
			(this.venda.tipoDeTransacao == 'Devolução' ||
			this.venda.tipoDeTransacao == 'Outros')
		) {
			this.iniciarNovaVenda()
		}
	}

	async projetaEstoqueDosItensDaVenda(itens: ItemDaVenda[]): Promise<boolean> {
		const projecoesPorItemDaVenda = await Promise.all(
			itens.map(async itemDaVenda => {
				let podePagarOsItens = true
				await this.findEstoqueUseCase
					.getEstoqueDoItemDaVendaNaLoja(
						itemDaVenda.produto.id,
						itemDaVenda.quantidade.toString(),
						this.turnoDeVenda?.pontoDeVenda.loja.id || '',
					)
					.catch(error => {
						podePagarOsItens = false
						AlertModule.setError(error.response.data[0])
					})
				return podePagarOsItens
			}),
		)
		return projecoesPorItemDaVenda.some(
			estoqueNegativo => estoqueNegativo === false,
		)
	}

	@Watch('venda', { immediate: true })
	onChangeVenda(vendaAtual: Venda, vendaAnterior: Venda | null) {
		if (
			!vendaAnterior ||
			vendaAtual.itens.length > vendaAnterior.itens.length
		) {
			setTimeout(() => {
				this.panelHeaders &&
					this.panelHeaders[this.panelHeaders.length - 1].$el.scrollIntoView()
			})
		}
	}

	confirmarCliente() {
		if (!this.formDoDialogoDeCliente.validate()) return
		this.$emit('update:pedindoCliente', false)
		this.cpfOuCnpjDoCliente = this.cpfOuCnpjDoClienteDoDialogo
		this.focarNaTela()
	}

	async confirmarSemCliente() {
		this.$emit('update:pedindoCliente', false)
		this.venda.cpfDoCliente = null
		this.venda.cliente = null
		this.cpfOuCnpjDoClienteDoDialogo = null
		this.cpfOuCnpjDoCliente = null
		this.focarNaTela()
	}

	@Watch('recarregaVenda')
	onRecarregarVenda(recarregarVenda: boolean) {
		if (!this.venda) return
		if (!recarregarVenda) return
		this.cpfOuCnpjDoCliente = formatarCnpjOuCpf(
			this.venda.cliente?.cnpjOuCpf || this.venda.cpfDoCliente || '',
		)
		this.recarregaVenda = false
	}

	@Watch('buscandoCliente', { immediate: true })
	onChangeBuscandoCliente(valor: boolean) {
		this.$emit('buscandoCliente', valor)
	}

	@Watch('venda.itens', { immediate: true })
	onChangeItensDaVenda(itensAnterior: ItemDaVenda[], itensAtuais: ItemDaVenda[]) {
		if (this.venda.isVendaReaberta && (this.venda.isConsignado || this.venda.isDemonstracao)) {
			if (itensAnterior.filter(item => item.isCompra).length > 0 && itensAtuais.filter(item => item.isCompra).length === 0) {
				this.venda.desconto = null
			}
		}
	}
}
