




























































































































import SeletorDeCliente from '@/views/application/clientes/SeletorDeCliente.vue'
import AlertModule from '@/store/vuex/aplicacao/AlertModule'
import {
	FluxoDoTitulo,
	Pessoa,
	SituacaoDoTitulo,
	TipoDeCliente,
	Titulo,
	Cliente,
	Loja,
	FormMovimentacaoDeCaixa,
	MovimentacaoDeCaixa,
	TipoDePessoa,
} from '@/models'
import { dateToPtBrFormat } from '@/shareds/date/date-utils'
import { Vue, Component, PropSync, Prop, Watch, Ref} from 'vue-property-decorator'
import axios, { CancelTokenSource } from 'axios';
import {SaveTituloUseCase } from '@/usecases/titulos'
import { FindTituloUseCase } from '@/usecases/titulos/FindTituloUseCase'
import { MovimentarCaixaUseCase } from '@/usecases'
import UserLoginStore from '@/store/vuex/authentication/UserLoginStore'
import DataTableDeCrud from '@/components/ui/DataTableDeCrud.vue'
import { DataOptions } from 'vuetify';
import CheckboxButton from '@/components/ui/CheckboxButton.vue'
import { criarMovimentacaoDeCaixa, imprimirCupomCrediario } from '@/shareds/venda-shareds'
import moment from 'moment'
import { VendaModule } from '@/store/vuex/venda/VendaStore'
import DialogoDeEdicaoDeTitulo from '@/views/application/titulos/DialogoDeEdicaoDeTitulo.vue'
import { BaixaDePagamento } from '@/models/financeiro/BaixaDePagamento'

@Component({
	components: {
		SeletorDeCliente,
		DataTableDeCrud,
		CheckboxButton,
		DialogoDeEdicaoDeTitulo,
	},
})
export default class DialogoDeRetornoDeCrediario extends Vue {
	@PropSync('mostra', { type: Boolean, default: false }) syncMostra!: boolean
	@Prop({ type: String, required: true }) fluxo!: FluxoDoTitulo
	@Prop({ type: Object, default: criarMovimentacaoDeCaixa }) valorInicial!: FormMovimentacaoDeCaixa
	@Prop() loja!: Loja
	@Prop({ type: String }) turnoDeVendaId!: string
	@Ref() dialogoDeEdicao!: DialogoDeEdicaoDeTitulo

	titulos: Titulo[] = []
	titulosAlterados: Titulo[] = []
	cliente: Cliente | null = null
	cancelToken: CancelTokenSource | null = null
	movimentacao = criarMovimentacaoDeCaixa()

	findTituloUseCase = new FindTituloUseCase()
	titulosSelecionados: DialogoDeRetornoDeCrediario['titulosFormatados'] = []
	saveTituloUseCase = new SaveTituloUseCase()
	movimentarCaixaUseCase = new MovimentarCaixaUseCase()
	
	todosForamSelecionados = false
	gerandoMovimentacao = false
	processandoImpressao = false
	carregando = false
	carregandoLista=  false
	totalRegistros = -1


	paginacao: DataOptions = {
		page: 0,
		itemsPerPage: 5,
		sortBy: [],
		sortDesc: [],
		groupBy: [],
		groupDesc: [],
		multiSort: false,
		mustSort: false,
	}
	

	headers = [
		{ text: 'Número', value: 'numero' },
		{ text: 'Parcela', value: 'sequencia' },
		{ text: 'Vencimento', value: 'dataDeVencimento' },
		{ text: 'Descrição', value: 'descricao' },
		{ text: 'Cliente', value: 'pessoa' },
		{ text: 'Valor', value: 'valor' },
		{ text: 'Valor Atualizado', value: 'valorAtualizado'},
		{ text: 'Forma de pagamento', value: 'formaDePagamento' },
	]

	get titulosFormatados() {
		return this.titulos.map(titulo => ({
			...titulo,
			dataDeVencimento: dateToPtBrFormat(titulo.dataDeVencimento),
			valor:
				titulo?.valor?.toLocaleString('pt-br', {
					style: 'currency',
					currency: 'BRL',
				}) || '',
			valorAtualizado: 
				titulo?.valorAtualizado?.toLocaleString('pt-br', {
					style: 'currency',
					currency: 'BRL',
				}) || '',
			pessoa: titulo.pessoa?.razaoSocialOuNome || '',
			loja: titulo.loja?.nomeFantasia || '',
			formaDePagamento: titulo.formaDePagamento
				? this.displayPagamento(titulo.formaDePagamento)
				: '',
		}))
	}

	get tokenDoUsuario() {
		return UserLoginStore.token
	}

	get tipoDePessoa(): TipoDePessoa {
		return this.fluxo == 'Entrada' ? 'Cliente' : 'Fornecedor'
	}
	async buscar() {
		try {
			if (!this.loja.id || !this.cliente) {
				return;
			}

			this.carregandoLista = true;
			this.cancelToken = axios.CancelToken.source();

			const params = {
				page: this.paginacao.page - 1,
				size: this.paginacao.itemsPerPage,
				pessoa: this.cliente.id,
				fluxo: this.fluxo,
				formaDePagamento: 'Crediário',
				situacaoDoTitulo: 'Aberto',
				loja: this.loja.id,
			};
			
			const axiosConfig = {
				cancelToken: this.cancelToken.token,
			};
			
			const pagina = await this.findTituloUseCase.find(params, axiosConfig);
			this.titulos = pagina.content;
			this.totalRegistros = pagina.totalElements;
			this.carregandoLista = false;
		} catch (error) {
			if (axios.isCancel(error)) return;
			AlertModule.setError(error);
			this.carregandoLista = false;
		}
	}


	async baixarTitulo(baixa: BaixaDePagamento) {
		if (!this.loja.id || !this.cliente) {
			return;
		}
		const titulosId = this.titulosSelecionados.map(
			itemDalista => itemDalista.id,
		)
		try {
			this.carregando = true
			this.titulosAlterados = await this.saveTituloUseCase.updateTitulos(
				titulosId,
				'Pago',
			)

			await this.adicionarMovimentacao(baixa.valorPagamento)

			await this.imprimirCupomDeCrediario(this.cliente.id, baixa)
		} catch (error) {
			AlertModule.setError(error)
		} finally {
			this.carregando = false
			AlertModule.setSuccess('Baixa de recebíveis realizada com sucesso!')
		}
		if (this.titulosAlterados.length) {
			for (let i = 0; i <= this.titulos.length - 1; i++) {
				this.titulosAlterados.forEach(tituloAlterado => {
					if (tituloAlterado.id == this.titulos[i].id)
						this.titulos.splice(i, 1, tituloAlterado)
				})
			}
		}
	}

	async adicionarMovimentacao(soma: number) {
		if (!this.turnoDeVendaId) {
			AlertModule.setError('Nenhum turno de venda ativo!')
			return
		}

		const movimentacao = {
			tipoMovimentacao: 'Reforço',
			valor: soma,
			dataHoraMovimentacao: moment().toDate(),
			observacao: 'Retorno De Crediario',
		}

		if (!movimentacao.valor)
			throw new Error('Não informado valor para movimentação')

		this.gerandoMovimentacao = true

		try {
			const movimentacaoAtualizada =  await this.movimentarCaixaUseCase.execute(this.turnoDeVendaId, movimentacao as MovimentacaoDeCaixa)
			VendaModule.addMovimentacao(movimentacaoAtualizada)
			
		} catch (error) {
			AlertModule.setError(error)
		} finally {
			this.gerandoMovimentacao = false
		}
	}

	async imprimirCupomDeCrediario(idCliente: string, baixa: BaixaDePagamento) {
		this.processandoImpressao = true
		try {
			await imprimirCupomCrediario(idCliente, baixa[0].id)
		} catch (error) {
			AlertModule.setError(error)
			this.processandoImpressao = false
		} finally {
			this.processandoImpressao = false
		}
	}

	displayPagamento(formaDePagamento: string) {
		let formaFormatada
		if (formaDePagamento == 'CREDIARIO') formaFormatada = 'Crediário'
		return formaFormatada
	}

	abrirDialogoDeEdicaoDeTitulo(indice: number) {
		this.dialogoDeEdicao.mostrarDialogo(this.titulos[indice], 'editar')
	}

	fechar(){
		this.syncMostra = false
	}

	clienteNaoExiste() {
		AlertModule.setInfo('Cliente não encontrado')
	}

	obterFiltrosDeTitulosIniciais(): FormDeFiltroDeTitulos {
		return {
			pessoa: null,
			tipoDeCliente: null,
			dataDeCompetencia: null,
			dataDeVencimento: null,
			situacaoDoTitulo: null,
			formaDePagamento: null,
			loja: null,
		}
	}

	aplicarFiltros() {
		this.paginacao = {
			...this.paginacao,
			page: 1,
		}
		this.buscar()
	}

	selecionarTodos() {
		this.titulosSelecionados = this.titulosFormatados
		this.todosForamSelecionados = true
	}

	limparSelecao() {
		this.titulosSelecionados = []
		this.todosForamSelecionados = false
	}

	@Watch('titulosSelecionados', { immediate: true })
	onChangeTitulosSelecionados() {
		if (this.titulosSelecionados.length !== this.titulosFormatados.length) {
			this.todosForamSelecionados = false
		}
	}

	@Watch('paginacao')
	onChangePaginacao(){
		this.buscar()
	}
	
}

export interface FormDeFiltroDeTitulos {
	pessoa: null | Pessoa
	tipoDeCliente: TipoDeCliente | null
	dataDeCompetencia: string | null
	dataDeVencimento: string | null
	situacaoDoTitulo: SituacaoDoTitulo | null
	formaDePagamento: string | null
	loja: string | null
}
