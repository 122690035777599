

























import { Vue, Component, Prop, Ref } from 'vue-property-decorator'
import { TipoDePagamento } from '@/models'
import { formatarMoeda } from '@/shareds/formatadores'

@Component
export default class CardDeTipoDePagamento extends Vue {
	@Prop() tipoDePagamento!: TipoDePagamento
	@Prop() indice!: number
	@Ref() card!: HTMLDivElement
	@Prop() disponivel?: number

	formatarMoeda = formatarMoeda

	click() {
		this.card.click()
	}
}

